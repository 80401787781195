import React, { useEffect, useState } from 'react'
import './advertiserBilling.css';
import './combineBilling.css';
import './advertiserBillingSummary.css';
import ReactSelect from 'react-select';
import { CssTextField, ReactSelectStyles } from '../../data/constants';
import { checkForPermission, getLocalStorageValue, getRequests, getUserType, isSuperUser, removeLocalStorageValue, setLocalStorageValue, sortingV2, useForceUpdate, zeroPad } from '../../util/util';
import { getAdvertisers, getManager } from '../../apis/dropdown/dropdowns';
import { Button, Pagination} from '@mui/material';
import TableModel from '../../components/tableModel/tablemodel';
import PopupComponent from '../../components/popup/popup';
import axios from 'axios';
import * as Constants from '../../data/constants';
import ShowLoaderComponent from '../../components/loader';
import { CircularProgress } from '@mui/material'
import NoResultsFound from '../../components/noResultFound';
import SomeErrorOccured from '../../components/someErrorOccured';
import ReactApexChart  from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ReactApexCharts from 'react-apexcharts';

const PaymentSummary = () => {
    document.title = 'Combine Advertiser Summary'
    const refreshComponent = useForceUpdate();
    var todayDate = new Date()
    todayDate.setMonth(todayDate.getMonth() - 1);
    var lastMonthList = todayDate.toISOString().split('T')[0].split('-')
    var lastMonth = lastMonthList[0]+'-'+lastMonthList[1]
    lastMonth = [lastMonth,null]

    if(document.getElementsByClassName('mainWindowSidebarClose').length>0){
        document.getElementsByClassName('mainWindowSidebarClose')[0].style.background = 'white'
    }
    const [sortingHelper, setSortingHelper] = useState({value:'conversions_verified__sum',order:'desc'})

    const queryParameters = new URLSearchParams(window.location.search)
    var tempMonthSelected = queryParameters.get('monthSelected')
    if(tempMonthSelected){
        if(tempMonthSelected.includes('_')){
            var tempMonthSelectedList = tempMonthSelected.split('_')
            if(tempMonthSelectedList[1]!=='undefined' && tempMonthSelectedList[1]!=='null'){
                tempMonthSelected = [new Date(tempMonthSelectedList[0]),new Date(tempMonthSelectedList[1])]
            }
            else{
                tempMonthSelected = [new Date(tempMonthSelectedList[0]),null]
            }
        }
        else{
            tempMonthSelected = new Date(tempMonthSelected)
            tempMonthSelected = [tempMonthSelected,null]
        }
    }
    var tempFilterData = {}
    var tempAdvertiserId = queryParameters.get('advertiser_id')
    var tempAdvertiserName = queryParameters.get('advertiser_name')
    var tempPackageName = queryParameters.get('package_name')
    var tempTeamName = queryParameters.get('team')
    const [rowDetailsPopup, setRowDetailsPopup] = useState(false)


    var subteamMembers = {
        Team1:['Pushkal','Pawan','Nitesh'],
        Team2:['Udit','Umair','Divyanshu'],
        Team3:['Shikha','Harsh','Aniket'],
    }
    var subBTteamMembers = {
        MA:['mobiaviator','quasarmobi','offersinfinite'],
        AH:['adshustle','appsfollowing','vestaapps'],
        MP:['mobpine','77ads','appamplify'],
    }
    var isClearableTeam = true
    var teamOptions = [{label:'Team1',value:'Team1'},{label:'Team2',value:'Team2'},{label:'Team3',value:'Team3'}]
    if(checkForPermission('can_view_team1') && !isSuperUser()){
        subteamMembers = {
            Team1:['Pushkal','Pawan','Nitesh'],
        }
        teamOptions=[{label:'Team1',value:'Team1'}]
        tempTeamName = 'Team1'
        isClearableTeam = false
    }
    if(checkForPermission('can_view_team2') && !isSuperUser()){
        subteamMembers = {
            Team2:['Udit','Umair','Divyanshu'],
        }
        teamOptions=[{label:'Team2',value:'Team2'}]
        tempTeamName = 'Team2'
        isClearableTeam = false
    }
    if(checkForPermission('can_view_team3') && !isSuperUser()){
        subteamMembers = {
            Team3:['Shikha','Harsh','Aniket'],
        }
        teamOptions=[{label:'Team3',value:'Team3'}]
        tempTeamName = 'Team3'
        isClearableTeam = false
    }
    if(tempPackageName){
        tempFilterData['package_name'] = tempPackageName
    }
    if(tempAdvertiserId){
        tempFilterData['advertiser'] = {value:tempAdvertiserId,label:tempAdvertiserName}
    }
    if(tempTeamName){
        tempFilterData['team'] = {value:tempTeamName,label:tempTeamName}
    }
    var showDirectYTD = queryParameters.get('showYTD') === 'True' ? true : false
    const [showRowDetails, setShowRowDetails] = useState(false)
    const [monthDetail, setMonthDetail] = useState(false)

    const [rowSelectedChannelData, setRowSelectedChannelData] = useState([])
    const [monthSelected, setMonthSelected] = useState(tempMonthSelected ? tempMonthSelected : getLocalStorageValue('billingFilters') && getLocalStorageValue('billingFilters').monthSelected ? getLocalStorageValue('billingFilters').monthSelected : lastMonth)
    const [loader, setLoader] = useState({main:false})
    const [manualUseEffect, setManualUseEffect] = useState(false)
    // const [optionsAdvertiserList, setOptionsAdvertiserList] = useState([])
    const [filterData, setFilterData] = useState(Object.keys(tempFilterData).length>0 ? tempFilterData : getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters') : {})
    // const [optionsManagerList, setOptionsManagerList] = useState([])
    const [tableValues, setTableValues] = useState([])
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [totalResults, setTotalResults] = useState(10);
    const [totalRow, setTotalRow] = useState([]);
    const [openedTabs, setOpenedTabs] = useState([])
    const [compareWithManual,setCompareWithManual] = useState(getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters').compare_with_manual ? true : false : false)
    const [sortingData,setSortingData] = useState(false)
    const [showDecimalPoints, setShowDecimalPoints] = useState(true)
    // const [datatillThisMonth,setDatatillThisMonth] = useState(false)
    const [calculateWith, setCalculateWith] = useState('conversions_verified')

    const getMonthWisePackageNameData = async(index) => {
        index = limit*(page-1)+index
        var fromMonth = filterData.monthSelected[0].toLocaleString('en-GB',{year:'numeric',month:'numeric'}).replace('/','-')

        try {
            var toMonth = filterData.monthSelected[1].toLocaleString('en-GB', {year: 'numeric', month: 'numeric'}).replace('/', '-');
        } catch (error) {
            var toMonth =  fromMonth
        }
        
        await axios({
            method: 'GET',
            url:"".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v2/report/monthwise"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params: { 
                fromMonth,
                toMonth,
                package_name:tableValues[index].package_name
            }
        })
        .then(async(resp) => {
            setMonthDetail(resp.data.data)
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                if(error.response.data){
                    setTableValues(error.response.data.result);
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })
        setLoader(false)
    }
    const getFromToMonth = () => {
        var date_temp = new Date(monthSelected[0]).toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
        var fromMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
        if(monthSelected[1]){
            date_temp = new Date(monthSelected[1]).toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
            var toMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
        }else{
            var toMonth = fromMonth
        }
        return {fromMonth,toMonth}
    }
    const getRevenueData = async(e,manual_limit=null,manual_page=null,sorting_key=null,sorting_order=null,exportReport=null) => {
        setLoader({...loader,main:true})
        setOpenedTabs([]);
        setOffset((manual_limit ? manual_limit : limit)*(manual_page ? (manual_page-1) : (page-1)))
        var {fromMonth,toMonth} = getFromToMonth()
        await axios({
            method: 'GET',
            url:"".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v1/invoice/feedback"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params: { 
                //filters
                fromMonth,
                toMonth,
                team:filterData.team ? filterData.team.value : undefined,
                subteam:filterData.subteam ? filterData.subteam.value : undefined,
                package_name:filterData.package_name ? filterData.package_name : undefined,
                app_name:filterData.app_name ? filterData.app_name : undefined,
                channel:filterData.channel ? filterData.channel.value : undefined,    
                limit: manual_limit ? manual_limit : limit,
                page: manual_page ? manual_page : page,
                order_by: "DESC",
                order_field: "approved_revenue",
                total_row_required: true,
                get_app_name:checkForPermission('can_view_app_name'),
                sorting_key: sorting_key ? sorting_key : sortingHelper.value,
                sorting_order:sorting_order ? sorting_order : sortingHelper.order,
                export:exportReport ? exportReport : null,
                
            }
        })
        .then(async(resp) => {
            if(exportReport){
                const FileDownload = require('js-file-download');
                FileDownload(resp.data, 'appwise_revenue_summary.csv')
                return
            }
            setTotalResults(resp.data.count)
            var items = resp.data.data
            sortingV2(items,sorting_key ? sorting_key : sortingHelper.value,(sorting_order ? sorting_order : sortingHelper.order) === 'desc' ? false : true)
            
            setTableValues(items);
            if(resp.data.total){
                setTotalRow([{package_name:'Total',...resp.data.total}])
            }
           
        })
        .catch((error) => {
            setTableValues([])
            if (error.response) {
                console.log(error.response.data);
                if(error.response.data){
                    setTableValues(error.response.data.result);
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })
        setLoader({...loader,main:false})
    }
    const sortingFunction = (value,order) => {
        if(order){
            order = 'asc'
        }
        else{
            order = 'desc'
        }
        if(['package_name','app_name','techteam','techsubteam'].includes(value)){

        }
        else{
            sortingV2(tableValues,'total',order==='asc',value)
            // getRevenueData(value,order)
        }
        setSortingHelper(value,order)
    }


    const updateFilterData = (value,item) => {
        if(item.value==='team' ){
            var updateFilterData = {[item.value]:value,subteam:false}
            setFilterData({...filterData,...updateFilterData})
        }
        else{
                setFilterData({...filterData,[item.value]:value})
            }
        var oldSessionFilterStorage = getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters') : {}
        setLocalStorageValue("billingFilters",{...oldSessionFilterStorage,[item.value]:value})
    }
    const resetFilters = () => {
        removeLocalStorageValue('billingFilters');
        setFilterData({})
        setMonthSelected(lastMonth);
        refreshComponent(123);
    }

    var subteamoptions = () => {
        if(filterData && filterData.team){
            if(filterData.team.value){
                return subteamMembers[filterData.team.value].map((item)=>{return {label:item,value:item}})
            }
        }
    }
    var subbtteamoptions = () => {
        var subteamlist = []
        subteamlist = [...subteamlist,...subBTteamMembers[process.env.REACT_APP_CHANNEL_SYMBOL].map((item)=>{return {label:capitalizeFirstLetter(item),value:item}})]
        return subteamlist
    }
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const filters = [
        {label:'Month', value:'month',type:'monthpicker'},
        {label:'Offer Package', value:'package_name',type:'textfield',setMethod:updateFilterData},
        // {label:'Advertiser',isSingle:true, value:'advertiser',type:'select',options:optionsAdvertiserList.advertiserlist,setMethod:updateFilterData},
        {label:'Tech Team',isSingle:true, value:'team',type:'select',options:teamOptions,setMethod:updateFilterData},
        {label:'Tech Sub Team',isSingle:true, value:'subteam',type:'select',options:subteamoptions(),setMethod:updateFilterData},
        {label:'App Name', value:'app_name',type:'textfield',setMethod:updateFilterData},
        {label:'Channel Name',isSingle:true, value:'channel',multiple:true,type:'select',options:subbtteamoptions(),setMethod:updateFilterData},
        {label:'Submit', disabled:loader.main,value:'submitButton',type:'button',onSubmit:getRevenueData,setMethod:updateFilterData},
        {label:'Reset', value:'resetButton',variant:'outlined',type:'button',onSubmit:resetFilters},
    ]
    
    if(getUserType()!=='general_manager'){
        filters.splice(3,1)
        filters.splice(3,1)
    }
    const validationComponent = (valueItem,item,index,valueIndex,tableValues) => {
        if(item.channel){
            var [validated_offers,total_offers] = [valueItem['validated_offers'], valueItem['total_offers']]
        }
        else{
            [validated_offers,total_offers] = valueItem['total'] ? [valueItem['total']['validated_offers'], valueItem['total']['total_offers']] : [0,0]

        }
        var percent = (validated_offers*100)/total_offers
        if (percent<25){
            var color = '#ff0000'
            var highlightColor = '#ffcccc'
        }
        else if (percent<50){
            color = '#ff6200'
            highlightColor = '#ffe6cc'
        }
        else if (percent<75){
            color = '#6f9400'
            highlightColor = '#ebffc2'
        }
        else{
            color = '#0f8500'
            highlightColor = '#d2ffcc'
        }
        if(valueItem.package_name==='Total'){
            total_offers = valueItem.total_offers
            validated_offers = valueItem.validated_offers
        }
        return <div style={{textDecoration:'none',color,fontWeight:500,background:highlightColor,marginLeft:(valueItem.advertiser_id==='Total' ? '5px' : '0px'),padding:'5px',width:'max-content',borderRadius:'10px'}}>
            {validated_offers+'/'+total_offers}
        </div>
    }
    
    const validationPercentageComponent = (valueItem,item,index,valueIndex,tableValues) => {
        var percent = valueItem['total'] ? valueItem['total'][item.value] ? parseInt(valueItem['total'][item.value]) : 0.00 : 0.00
        
        if(item.channel){
            percent = valueItem[item.value] ? parseInt(valueItem[item.value]) : 0.00 
        }
        if(valueItem.package_name==='Total'){
            percent = valueItem[item.value] ? parseInt(valueItem[item.value]) : 0.00 
        }
        if (percent<25){
            var color = '#ff0000'
            var highlightColor = '#ffcccc'
        }
        else if (percent<50){
            color = '#ff6200'
            highlightColor = '#ffe6cc'
        }
        else if (percent<75){
            color = '#6f9400'
            highlightColor = '#ebffc2'
        }
        else{
            color = '#0f8500'
            highlightColor = '#d2ffcc'
        }

        return <div style={{color,fontWeight:500,background:highlightColor,marginLeft:(valueItem.advertiser_id==='Total' ? '5px' : '0px'),padding:'5px',width:'max-content',borderRadius:'10px'}}>
            {percent.toString()+'%'}
        </div>
    }

    const [activeTab, setActiveTab] = useState(false)
    const showDetailSummaryComponent = (valueItem,item,index,valueIndex,tableValues) => {
        if(valueItem.package_name==='Total'){
            return ''
        }
        return <div style={{width:'80%',color:localStorage.getItem('ambientColor'),cursor:'pointer'}} 
        onClick={(e)=>{setActiveTab(valueIndex);
                        getRevenueDataMonthlyForAdv(e,valueItem.package_name,valueIndex);
                        for(var index in openedTabs){
                            var item = openedTabs[index];
                            if(item.valueIndex === valueIndex){
                                var donotpush = true;
                            }
                        }  
                        if(!donotpush){
                            setOpenedTabs([...openedTabs.slice(-4),{valueIndex,...valueItem}])
                        }
        }}>
                Show Summary
            </div>
    }
    const roundComponent = (valueItem,item,index,valueIndex,tableValues) => {
        
        if(valueItem.package_name==='Total'){
            var value = valueItem[item.value] ? parseInt(valueItem[item.value]) : 0.00 
        }
        else if(item.channel){
            value = Math.round(valueItem[item.value])
        }
        else{
            value = valueItem['total'] ? Math.round(valueItem['total'][item.value]) : 0
        }
        return <div style={{marginLeft:(valueItem.package_name === 'Total' ? '5px' : 0),marginRight:(valueItem.package_name === 'Total' ? '5px' : 0),...item.style}}>
            {value.toLocaleString('en', {useGrouping:true})}
        </div>
    }

    function recursive_offset (aobj) {
        var currOffset = {
          x: 0,
          y: 0
        } 
        var newOffset = {
            x: 0,
            y: 0
        }    
       
        if (aobj !== null) {
       
          if (aobj.scrollLeft) { 
            currOffset.x = aobj.scrollLeft;
          }
       
          if (aobj.scrollTop) { 
            currOffset.y = aobj.scrollTop;
          } 
       
          if (aobj.offsetLeft) { 
            currOffset.x -= aobj.offsetLeft;
          }
       
          if (aobj.offsetTop) { 
            currOffset.y -= aobj.offsetTop;
          }
       
          if (aobj.parentNode !== undefined) { 
             newOffset = recursive_offset(aobj.parentNode);   
          }
       
          currOffset.x = currOffset.x + newOffset.x;
          currOffset.y = currOffset.y + newOffset.y; 
        }
        return currOffset;
    }
    const clickOnRow = (e,rowItem,rowIndex) => {
        e.preventDefault();
        e.stopPropagation();
        if(e.type==='contextmenu'){
            var offsetpos = recursive_offset(document.getElementById('scriptrevenue'))
            var posX = e.clientX+offsetpos.x;
            var posY = e.clientY+offsetpos.y;
            setRowDetailsPopup({top:posY,left:posX,rowItem,rowIndex})
        }
    }
    
    const rowDetailsBodyFunction = () => {
        var channelTypeHeaders = [
            {channel:true,label:'Channel',value:'channel',width:120,locked:true,showTooltip:true,customComponent:appNameComponent},
            {channel:true,label:'App Name',value:'app_name',width:120,hide:false,customComponent:appNameComponent},
            {channel:true,label:'Tech Team',value:'techteam',width:120,hide:false},
            {channel:true,label:'Tech Sub Team',value:'techsubteam',width:120,hide:false},
            {channel:true,label:'Offers Validated',value:'validated_offers',width:120,hide:false,customComponent:validationComponent},
            {channel:true,label:'Delivered Qty',value:'conversions__sum',width:100,hide:true},
            {channel:true,label:'Delivered Revenue',value:'delivered_revenue__sum',width:100,customComponent:roundComponent,hide:true},
            {channel:true,label:'Declined Revenue',value:'declined_revenue__sum',width:100,customComponent:roundComponent,hide:true},
            {channel:true,label:'Verified Revenue',value:'conversions_verified__sum',width:100,hide:false,customComponent:roundComponent},
            {channel:true,label:'Approved Revenue',value:'manager_approved_revenue__sum',width:100,hide:false,customComponent:roundApprovedComponent},
            {channel:true,label:'Validation Percentage',value:'validation_percentage'+(compareWithManual ? '_manual' : ''),width:120,hide:false,customComponent:validationPercentageComponent,hideSorting:false},
            {channel:true,label:'Validation Percentage Effective',value:'validation_percentage_effective'+(compareWithManual ? '_manual' : ''),width:120,hide:false,customComponent:validationPercentageComponent,hideSorting:false},
            {channel:true,label:'Deduction',value:'deduction_revenue'+getCalculationLabel(calculateWith),width:100,hide:false,customComponent:roundComponent},
            {channel:true,label:'Pending',value:'pending_revenue'+getCalculationLabel(calculateWith),width:100,hide:false,customComponent:roundComponent},
        ]

        return <TableModel
                serialOffset={limit*(page-1)}
                tableHeaders={channelTypeHeaders}
                tableValues={rowSelectedChannelData}
                setTableValues={setRowSelectedChannelData}
                style={{background:'rgb(245,245,245)'}}
                tableValueRow={'rest'}
                setTableValueRow={()=>{console.log('test')}}
                totalRow={[{...showRowDetails.total,package_name:'Total'}]}
                hideHeaderSettings={true}
                tableRowClickFunction={clickOnRow}
                // sortingHelper={sortingHelper}
                // setSortingHelper={setSortingHelper}
                sortingFunction={sortingFunction}
                // tableRowClickFunction={clickOnRow}
                // exportFunction={()=>{getRevenueData(null,null,null,null,null,true)}}
            />
    }

    const rowMonthlyDataFunction = () => {
        var state = {
            series: monthDetail[showRowDetails.package_name],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                dataLabels: {
                    enabled:false
                },
                plotOptions: {
                    bar: {
                    horizontal: false
                    }
                },
                grid:{
                    show: true,
                    // borderColor: props.darkMode ? 'rgb(50,50,50)' : 'rgb(200,200,200)',
                },
                xaxis: {
                    categories: monthDetail.month_list
                },
                fill: {
                    opacity: 1
                },
                // pending:rgb(254,175,26)
                // validated:rgb(0,227,150)
                // deducted:rgb(254,69,96)
                colors: [
                    'rgb(0,227,150)','rgb(254,69,96)','rgb(254,175,26)',
                    'rgb(0,227,150)','rgb(254,69,96)','rgb(254,175,26)',
                    'rgb(0,227,150)','rgb(254,69,96)','rgb(254,175,26)',
                    'rgb(0,227,150)','rgb(254,69,96)','rgb(254,175,26)',
                    'rgb(0,227,150)','rgb(254,69,96)','rgb(254,175,26)',
                    'rgb(0,227,150)','rgb(254,69,96)','rgb(254,175,26)',
                    'rgb(0,227,150)','rgb(254,69,96)','rgb(254,175,26)',
                    ],
                yaxis: {
                    min:0,
                    forceNiceScale:true,
                    labels: {
                        formatter: (val) => {
                            return val 
                        }
                    }
                },
                
                legend: {
                    show:true,
                    customLegendItems: ['Validated Revenue','Deducted Revenue','Pending Revenue'],
                    position: 'top',
                    horizontalAlign: 'left',
                },
                theme:{
                    // mode:props.darkMode ? 'dark' : 'light',
                },
                tooltip:{
                    enabled:true,
                    // theme:props.darkMode ? 'dark' : 'light',
                    
                }
            },
        };
        return <div style={{overflow:'auto'}}>
            {
                !monthDetail
                &&
                <div style={{display:'flex',height:'50vh',justifyContent:'center',alignItems:'center'}}>
                    <CircularProgress sx={{fontSize:'14px',color:localStorage.getItem('ambientColor')}}/>
                </div>
            }
            {
                monthDetail && state.series
                &&
                <ReactApexCharts options={state.options} series={state.series} type="bar" height={500} />
            }
        </div>
    }


    const roundApprovedComponent = (valueItem,item,index,valueIndex,tableValues) => {
        if(valueItem.package_name==='Total'){
            return <div style={{marginLeft:'5px',marginRight:'5px',...item.style}}>
                {Math.round(valueItem[item.value]).toLocaleString('en', {useGrouping:true})}
            </div>
        }
        if(item.channel && valueItem.channel){
            return (valueItem.validated_offers!=="0" ? Math.round(valueItem[item.value]) : 'Pending')
        }
        return (valueItem.validated_offers!=="0" ? Math.round(valueItem['total'][item.value]).toLocaleString('en', {useGrouping:true}) : 'Pending')
    }

    const diffManualPanelPercent = (valueItem,item,index,valueIndex,tableValues) => {
        var percent = valueItem[item.value] ? showDecimalPoints ? valueItem[item.value].toFixed(2) : parseInt(valueItem[item.value]) : 0.00
        var color = 'rgb(255,0,0)'
        var highlightColor = 'rgb(255, 166, 166)'
        if (percent>=90 && percent<=110){
            color = 'rgb(23, 100, 0)'
            highlightColor = 'rgb(152, 192, 140)'
        }

        return <div style={{color,marginLeft:(valueItem.advertiser_id==='Total' ? '5px' : '0px'),padding:'5px',width:'max-content',borderRadius:'20px'}}>
            {percent.toString()+'%'}
        </div>
    }
    const appNameComponent = (valueItem,item) => {
        var {fromMonth,toMonth} = getFromToMonth()
        return <div style={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}} onClick={()=>{window.open('/combine/advertiserPaymentSummary?monthSelected='+fromMonth+'_'+toMonth+'&package_name='+valueItem.package_name)}}>
            {valueItem[item.value]}
        </div>
    }


    const paymentPendingPercentage = (valueItem,item,index,valueIndex,tableValues) => {
        var percent = valueItem[item.value] ? showDecimalPoints ? valueItem[item.value].toFixed(2) : parseInt(valueItem[item.value]) : 0.00
        if (percent<25){
            var color = '#0f8500'
            var highlightColor = '#d2ffcc'
        }
        else if (percent<50){
            color = '#6f9400'
            highlightColor = '#ebffc2'
        }
        else if (percent<75){
            color = '#ff6200'
            highlightColor = '#ffe6cc'
        }
        else{
            color = '#ff0000'
            highlightColor = '#ffcccc'
        }

        return <div style={{color,fontWeight:500,background:highlightColor,marginLeft:(valueItem.advertiser_id==='Total' ? '5px' : '0px'),padding:'5px',width:'max-content',borderRadius:'10px'}}>
            {valueItem.validated ? percent.toString()+'%' : 'Pending'}
        </div>
    }
    const getCalculationLabel = (value) => {
        var dict_ = {
            'delivered_revenue':{label:'_delivered'},
            'conversions_verified':{label:'_manual'},
            'total_revenue':{label:''},
        }
        return dict_[value].label
    }

    const menuOptions = {
        'Channel Wise Details':rowDetailsBodyFunction,
        'Month Wise Details':rowMonthlyDataFunction,
    }
    useEffect(() => {
        document.addEventListener('click',e=>{
            setRowDetailsPopup(false);
        })
        
        return () => {
            document.removeEventListener('click',e=>{
                setRowDetailsPopup(false);
            })
        }
    }, [])
    
    const tempTableHeaders = [
        {label:'Package Name',value:'package_name',width:120,locked:true,showTooltip:true,customComponent:appNameComponent},
        {label:'App Name',value:'app_name',width:120,hide:false,customComponent:appNameComponent},
        {label:'Tech Team',value:'techteam',width:120,hide:false},
        {label:'Tech Sub Team',value:'techsubteam',width:120,hide:false},
        {label:'Offers Validated',value:'validated_offers',width:120,hide:false,customComponent:validationComponent},
        {label:'Delivered Qty',value:'conversions__sum',width:100,hide:true,style:{textAlign:'right'}},
        {label:'Delivered Revenue',value:'delivered_revenue__sum',width:100,customComponent:roundComponent,hide:true,style:{textAlign:'right'}},
        {label:'Declined Revenue',value:'declined_revenue__sum',width:100,customComponent:roundComponent,hide:true,style:{textAlign:'right'}},
        {label:'Verified Revenue',value:'conversions_verified__sum',width:100,hide:false,customComponent:roundComponent,style:{textAlign:'right'}},
        {label:'Approved Revenue',value:'manager_approved_revenue__sum',width:100,hide:false,customComponent:roundApprovedComponent,style:{textAlign:'right'}},
        {label:'Validation Percentage',value:'validation_percentage'+(compareWithManual ? '_manual' : ''),width:120,hide:false,customComponent:validationPercentageComponent,hideSorting:false,style:{textAlign:'right'}},
        {label:'Validation Percentage Effective',value:'validation_percentage_effective'+(compareWithManual ? '_manual' : ''),width:120,hide:false,customComponent:validationPercentageComponent,hideSorting:false,style:{textAlign:'right'}},
        {label:'Deduction',value:'deduction_revenue'+getCalculationLabel(calculateWith),width:100,hide:false,customComponent:roundComponent,style:{textAlign:'right'}},
        {label:'Pending',value:'pending_revenue'+getCalculationLabel(calculateWith),width:100,hide:false,customComponent:roundComponent,style:{textAlign:'right'}},
    ]
    if(getUserType()!=='general_manager'){
        filters.splice(2,1)
        filters.splice(2,1)
    }
    // const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)
    useEffect(() => {
        document.addEventListener('click',e=>{

        })
        document.addEventListener('keydown',e=>{
            if(e.key==='Escape'){
                setActiveTab(false)
            }
        })
        return () => {
            document.removeEventListener('click',e=>{
            })
            document.removeEventListener('keydown',e=>{
                if(e.key==='Escape'){
                    setActiveTab(false)
                }
            })
        }
    }, [])

    const [montlyAdvertiserData, setMontlyAdvertiserData] = useState({})
    const getRevenueDataMonthlyForAdv = async(e,package_name,valueIndex) => {
        if(!montlyAdvertiserData[package_name]){
            var tableValue = await axios({
                method: 'GET',
                url:"".concat(Constants.PROTOCOL,Constants.HOST,"/api/admin/v1/invoice/feedback"),
                headers: {
                    "Content-Type":"application/json",
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                params: { 
                    //filters
                    year:true,
                    package_name
                    // currency:(manualCurrency ? manualCurrency : currency).toLowerCase(),
                    }
            })
            .then(async(resp) => {
                var item_dict = resp.data
                sortingV2(item_dict,'month',true)
                return item_dict
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    }
            })
            setMontlyAdvertiserData({...montlyAdvertiserData,[valueIndex]:tableValue})
            if(showDirectYTD){
                setActiveTab(0)
            }
        }
    }
    const summaryActivePopupData = () => {
        const getSeries = (value) => {
            if(montlyAdvertiserData[activeTab]){
                var series = montlyAdvertiserData[activeTab].map(item=>{
                    return item[value]
                })
            }
            else{
                series = []
            }
            return series
        }
        const state = {
            series: [
                {
                    name: "Delivered Quantity",
                    type:"line",
                    data: getSeries('conversions')
                  },
                  {
                    name: "Validation Percentage",
                    type:"line",
                    data: compareWithManual ? getSeries('validation_percentage_manual') : getSeries('validation_percentage')
                  },
                {
                  name: "Revenue",
                  type:"column",
                  data: compareWithManual ? getSeries('conversions_verified') : getSeries('total_revenue')
                },
                {
                  name: "Approved Revenue",
                  type:"column",
                  data: getSeries('manager_approved_revenue')
                },
                {
                  name: "Deduction Revenue",
                  type:"column",
                  data: compareWithManual ? getSeries('deduction_revenue_manual') : getSeries('deduction_revenue')
                },
                {
                  name: "Pending Validations",
                  type:"column",
                  data: compareWithManual ? getSeries('pending_revenue_manual') : getSeries('pending_revenue')
                },
              ],
            options: {
              plotOptions: {
                bar: {
                  columnWidth: "30%"
                }
              },
              chart: {
                height: 350,
                width: 850,
                type: 'line',
                  dropShadow: {
                    enabled: false,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                  },
                  toolbar: {
                    show: false
                  },
                  zoom: {
                    enabled: false,
                  }
                },
                colors: ['#8a2900','#8a2900','#8B75D7','#50E7A6','#FF6378','#FEBC4A'],
              dataLabels: {
                enabled: false,
              },
              grid:{
                show:true
              },
              markers: {
                  size: 4
                },
    
              stroke:{
                width:2,
                curve: 'smooth',
              },
              xaxis: {
                  crosshairs:{
                        show:false,
                  },  
                  labels: {
                    
                  },
                  // type: ',
                  tickAmount: 'dataPoints',
                  categories:getSeries('month').map((item)=>{
                    return new Date(item).toLocaleDateString('en-US',{month:'long'})
                  }),
                  forceNiceScale:true,
    
              },
              yaxis: [
                { 
                  min:Math.min(0),
                  max:Math.max(...getSeries('conversions'))+100,
                    forceNiceScale:true,
                    show:false,
                    opposite: true,
                    tickAmount: 5,
                    labels:{
                        formatter: (value) => { return showDecimalPoints ? value.toFixed(2) : parseInt(value) },
                    },
                },
                { 
                    // min:Math.min(0),
                    // max:Math.max(...getSeries('validation_percentage')),
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return (value ? showDecimalPoints ? value.toFixed(2) : parseInt(Math.round(value)) : 0 )+'%'},
                      },
                  },
                  { 
                    min:Math.min(0),
                    max:Math.max(...getSeries('total_revenue'))+100,
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return value ? showDecimalPoints ? value.toFixed(2) : parseInt(Math.round(value)) : 0 },
                      },
                  },
                  { 
                    min:Math.min(0),
                    max:Math.max(...getSeries('total_revenue'))+100,
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return value ? showDecimalPoints ? value.toFixed(2) : parseInt(Math.round(value)) : 0 },
                      },
                  },
                  { 
                    min:Math.min(0),
                    max:Math.max(...getSeries('total_revenue'))+100,
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return value ? showDecimalPoints ? value.toFixed(2) : parseInt(Math.round(value)) : 0 },
                      },
                  },
                  { 
                    min:Math.min(0),
                    max:Math.max(...getSeries('total_revenue'))+100,
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return value ? showDecimalPoints ? value.toFixed(2) : parseInt(Math.round(value)) : 0 },
                      },
                  },
            ],
              
              legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: 0,
                offsetX: -5
              }
            },
        };
        
        const monthComponent = (valueItem,item) => {
            return <a style={{textDecoration:'none',color:'blue'}} href={'/billing/advertiser?monthSelected='+valueItem.month+"&package_name="+valueItem.package_name} target='_blank'>
                {new Date(valueItem[item.value]).toLocaleDateString('en-US',{year:'numeric',month:'long'})}
            </a>
        }
        var ytdTableHeaders = [
            {label:'Month',value:'month',width:120,customComponent:monthComponent,hideSorting:true},
            {label:'Offers Validated',value:'validated',width:120,customComponent:validationComponent,hideSorting:true},
            // {label:'Delivered Qty',value:'conversions',width:100,hideSorting:true},
            // {label:'Delivered Revenue',value:'delivered_revenue',width:100,customComponent:roundComponent,hideSorting:true},
            // {label:'Declined Revenue',value:'declined_revenue',width:100,customComponent:roundComponent,hideSorting:true},
            {label:'Panel Revenue',value:'total_revenue',width:100,customComponent:roundComponent,hideSorting:true},
            {label:'Verified Revenue',value:'conversions_verified',width:100,customComponent:roundComponent},
            {label:'Revenue Difference',value:'diff_manual_panel',width:100,customComponent:roundComponent},
            {label:'Panel Revenue Accuracy',value:'diff_manual_panel_percent',width:100,customComponent:diffManualPanelPercent},    
            {label:'Approved Revenue',value:'manager_approved_revenue',width:100,customComponent:roundComponent,hideSorting:true},
            {label:'Validation Percentage',value:'validation_percentage'+(compareWithManual ? '_manual' : ''),width:120,customComponent:validationPercentageComponent,hideSorting:true},
            {label:'Deduction',value:'deduction_revenue'+(compareWithManual ? '_manual' : ''),width:100,customComponent:roundComponent},
            {label:'Pending Validations',value:'pending_revenue'+(compareWithManual ? '_manual' : ''),width:100,customComponent:roundComponent},
        ]
        return <div className='summaryActiveTabData'>
            {/* <div style={{fontSize:'17px',fontWeight:'500',marginBottom:'20px'}}>
                Summary of {new Date(monthSelected).toLocaleDateString('en-US',{month:'long',year:'numeric'})}
            </div>
            <table>
                <tbody>
                    <tr>
                        <td style={{width:'200px'}}>Total Revenue</td>
                        <td>{tableValues[activeTab].total_revenue}</td>
                    </tr>
                    <tr>
                        <td>Approved Revenue</td>
                        <td>{tableValues[activeTab].manager_approved_revenue}</td>
                    </tr>
                </tbody>
            </table> */}
            <div style={{fontSize:'17px',fontWeight:'500',marginTop:'10px',marginBottom:'20px'}}>
                Summary of {new Date(monthSelected).toLocaleDateString('en-US',{year:'numeric'})}
            </div>
            {
                !montlyAdvertiserData[activeTab]
                &&
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',minHeight:'200px'}}>
                    <CircularProgress sx={{color:localStorage.getItem('ambientColor')}}/>
                </div>
            }   
            {
                montlyAdvertiserData[activeTab]
                &&
                <ReactApexChart className="graphs" options={state.options} series={state.series} type="line" height={"350px"} width={"100%"}/>
            }
            {
                montlyAdvertiserData[activeTab]
                &&
                <TableModel
                    serialOffset={offset}
                    tableHeaders={ytdTableHeaders}
                    tableValues={montlyAdvertiserData[activeTab] ? montlyAdvertiserData[activeTab] : []}
                    setTableValues={()=>{}}
                    style={{background:'rgb(245,245,245)'}}
                    tableValueRow={'rest'}
                    setTableValueRow={()=>{console.log('test')}}
                    />
            }
        </div>
    }

    if(!manualUseEffect){
        setManualUseEffect(true)
        // getRequests(getAdvertisers(false,'both'),setOptionsAdvertiserList)
        // getRequests(getManager(),setOptionsManagerList)
        getRevenueData(1)
        if(showDirectYTD){
            getRevenueDataMonthlyForAdv('123',tempPackageName,0)
        }
    }
    return (
        <div className='advertiserBillingContainer'>
            <br/>
            <div style={{position:'sticky',left:0,width:'100%',zIndex:'999'}}>
                <div>

                <div className='advertiserLabel'>Combine App Name Summary</div>
                    <div className='advertiserFilters'>
                            
                        {filters.map((filterItem,filterIndex)=>{
                            return <div className='filterItem' key={filterIndex}>
                                    {filterItem.type !=='button' && <div className='filterItemLabel'>{filterItem.label}</div>}
                                    {filterItem.type === 'monthpicker'
                                    &&
                                    <DatePicker
                                        // selected={new Date(monthSelected)}
                                        customInput={<input className='monthPicker'/>}
                                        selectsRange
                                        startDate={new Date(monthSelected[0])}
                                        endDate={monthSelected[1] ? new Date(monthSelected[1]) : null}
                                        onChange={(date)=>{
                                            var date_temp = date[0].toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
                                            date[0] = date_temp[1]+'-'+zeroPad(date_temp[0],2)
                                            if(date[1]){
                                                date_temp = date[1].toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
                                                date[1] = date_temp[1]+'-'+zeroPad(date_temp[0],2)
                                            }
                                            setMonthSelected(date);
                                            var oldSessionFilterStorage = getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters') : {}
                                            setLocalStorageValue('billingFilters',{...oldSessionFilterStorage,monthSelected:date});}}
                                        showMonthYearPicker={true}
                                        dateFormat={'MMMM yyyy'}
                                    />
                                    }
                                    {filterItem.type === 'textfield'
                                    &&
                                    <CssTextField variant='standard' value={filterData[filterItem.value] ? filterData[filterItem.value] : ''} onChange={e=>{filterItem.setMethod(e.target.value,filterItem);}}/>}
                                    {filterItem.type === 'select'
                                    &&
                                    <div style={{minWidth:200}}>
                                        <ReactSelect 
                                            isClearable={filterItem.value==='team' ? isClearableTeam : true}
                                            options={filterItem.options}
                                            value={filterData[filterItem.value] ? filterData[filterItem.value] : ''}
                                            isMulti={filterItem.isSingle ? false : true}
                                            onChange={(e)=>{filterItem.setMethod(e,filterItem)}}
                                            menuPortalTarget={document.body} 
                                            styles={{...ReactSelectStyles}}/>
                                    </div>
                                    }
                                    {
                                        filterItem.type === 'button'
                                        &&
                                        <div style={{marginTop:'21px'}}>
                                            <Button id='testbutton' disabled={filterItem.disabled} onClick={filterItem.onSubmit} sx={{...(filterItem.variant && filterItem.variant==='outlined' ? Constants.OUTLINEDBUTTON : Constants.CONTAINEDBUTTON),fontSize:'14px',minWidth:'102px',minHeight:'40px'}}>
                                                {filterItem.disabled
                                                &&
                                                <CircularProgress size={'15px'} sx={{color:'white'}}/>
                                                }
                                                {!filterItem.disabled && filterItem.label}
                                            </Button>
                                        </div>
                                    }
                                </div>
                        })}
                    </div>
                    <div style={{display:'flex'}}>
                        <div style={{paddingLeft:'20px'}}>
                            <input checked={compareWithManual} id='compareWithManual' type='checkbox' onChange={(e)=>{setCompareWithManual(e.target.checked);updateFilterData(e.target.checked,{value:'compare_with_manual'});}}/>
                            <label style={{fontSize:'14px'}} htmlFor='compareWithManual' onChange={(e)=>{setCompareWithManual(e.target.checked);updateFilterData(e.target.checked,{value:'compare_with_manual'})}}>Calculation with Verified Revenue</label>
                        </div>
                        <div style={{paddingLeft:'20px'}}>
                            <input checked={showDecimalPoints} id='showDecimalPoints' type='checkbox' onChange={(e)=>{setShowDecimalPoints(e.target.checked);updateFilterData(e.target.checked,{value:'exclude_mmp_rebroking'});}}/>
                            <label style={{fontSize:'14px'}} htmlFor='showDecimalPoints' onChange={(e)=>{setShowDecimalPoints(e.target.checked);updateFilterData(e.target.checked,{value:'exclude_mmp_rebroking'})}}>Show Decimal Points</label>
                        </div>
                        {/* <div style={{paddingLeft:'20px'}}>
                            <input checked={datatillThisMonth} id='dataTillThisMonth' type='checkbox' onChange={(e)=>{setDatatillThisMonth(e.target.checked);updateFilterData(e.target.checked,{value:'data_till_this_month'});}}/>
                            <label style={{fontSize:'14px'}} htmlFor='dataTillThisMonth' onChange={(e)=>{setDatatillThisMonth(e.target.checked);updateFilterData(e.target.checked,{value:'data_till_this_month'})}}>Get Data till this month.</label>
                        </div> */}
                    </div>
                </div>
            </div>
            {loader.main
            &&
            <ShowLoaderComponent/>
            }
            {
            !loader.main
            &&
            typeof tableValues === 'object' && 
            tableValues.length>0 
            &&
            <TableModel
                serialOffset={offset}
                tableHeaders={tempTableHeaders}
                tableValues={tableValues}
                setTableValues={setTableValues}
                style={{background:'rgb(245,245,245)'}}
                tableValueRow={'rest'}
                setTableValueRow={()=>{console.log('test')}}
                totalRow={totalRow.length>0 ? totalRow : undefined}
                sortingFunction={sortingFunction}
                exportFunction={()=>{getRevenueData(null,null,null,null,null,true)}}
                tableRowClickFunction={clickOnRow}

                />
            }
            {/* {!loader && tableValues.length/limit>1 && typeof tableValues === 'object' && tableValues.length>0 && 
                <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,left:0,background:'var(--dark-mode)',color:'var(--light-mode)',transition:'all 0.5s ease-in-out',zIndex:899}}>
                    <div>
                        <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                        <select className='paginationSelect' value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{setLimit(parseInt(e.target.value));setPage(1);}}>
                            {[5,16,10,50,100,200,300,500,1000].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </select>
                    </div>
                    <Pagination count={Math.ceil(tableValues.length/limit)} page={page} onChange={(event,value)=>{setPage(value);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                </div>
            } */}
            {
                rowDetailsPopup 
                &&
                <div className='menuContainerCombine' style={{left:rowDetailsPopup.left,top:rowDetailsPopup.top}}>
                    {Object.keys(menuOptions).map((item,index)=>{
                        return <div className='menuItem' key={index} onClick={()=>{
                                    var rowItem = rowDetailsPopup.rowItem
                                    var rowIndex = rowDetailsPopup.rowIndex
                                    rowItem.total['channel'] = 'Total'
                                    rowItem.total['package_name'] = 'Total'
                                    getMonthWisePackageNameData(rowIndex)
                                    setShowRowDetails({...rowItem,popupLabel:item})
                                    var channelRows = []
                                    for(var channel in rowItem){
                                        var channelData = rowItem[channel];
                                        if(typeof channelData === 'object' && channelData !== null){
                                            if(channel!=='total'){
                                                channelRows.push({channel,...channelData})
                                            }
                                        }
                                    }
                                    setRowSelectedChannelData(channelRows)
                                }}>
                                {item}
                            </div>
                    })}
                    
                </div>
            }
            {
                showRowDetails 
                &&
                <PopupComponent 
                    title={showRowDetails.app_name + ' - '+ showRowDetails.popupLabel} 
                    body={menuOptions[showRowDetails.popupLabel]}
                    closePopupFunction={()=>{setShowRowDetails(false);setMonthDetail(false);}}
                />
            }
            {!loader.main
                &&typeof tableValues === 'object' && 
                tableValues.length===0 &&
                <NoResultsFound/>
            }
            {!loader.main
                &&
                typeof tableValues === 'string' &&
                <SomeErrorOccured error={tableValues}/>
            }
            
        </div>
    )
}

export default PaymentSummary