import React from 'react'
import './popup.css';

const PopupComponent = (props) => {
    const closePopup = (e) => {
        if(props.closePopupFunction){props.closePopupFunction(false)}
    }
    return (
        <div className='popupContainer' onClick={closePopup}>
            <div className='popupWrapper' onClick={(e)=>{e.stopPropagation();}}>
                <div className='popupHeaderFlex'>
                    <div><b>{props.title ? props.title : 'Popup'}</b></div>
                    <div style={{cursor:'pointer'}} onClick={closePopup}>X</div>
                </div>
                <div className='popupBody'>
                    {props.body()}
                </div>
            </div>
        </div>
    )
}

export default PopupComponent