import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import Slider from './Slider';
import arrowdown from "../assets/arrow-down.svg";
import PercentageDiv from "./Percentagediv";
import '../insightsCss.css';
const customStyles = {
  container: (base) => ({
    ...base,
    flexGrow: "1",
    margin: "0 1px",
    height: "60px",
    position: "relative",
  }),
  control: (base) => ({
    ...base,

    justifyContent: "center",
    alignItems: "center",
    border: 0,
    boxShadow: "none",
    backgroundColor: "#CFE1EE",
  }),
  valueContainer: (base) => ({
    ...base,
    flex: "unset",
    height: "60px",
    maxHeight: "40px",
  }),
  menu: (base) => ({
    ...base,
    position: "absolute",
    width: "15rem",
  }),
  placeholder: (base) => ({
    ...base,
    color: "#252525",
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#252525",
      backgroundColor: isSelected
        ? "#E0F1FB"
        : isFocused
        ? "#E0F1FB"
        : undefined,
      textAlign: "left",
      fontSize: "1rem",
    };
  },
};

const IndicatorsContainer = (props) => {
  return <img className="icon1" src={arrowdown} alt="indicator" />;
};
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
const CustomSingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>{null}</components.SingleValue>
);
const CustomOption = (props) => {
  return (
    <components.Option {...props} style={{ textAlign: "left" }}>
      <div style={{ textAlign: "left" }}>
        <input type="radio" defaultChecked={false} />
        <label>{props.label}</label>
      </div>
    </components.Option>
  );
};

const options2 = [
  { value: "ascending", label: "Sort in Ascending order" },
  { value: "descending", label: "Sort in Descending order" },
];
function CustomSelect(props) {
  return (
    <Select
      img={props.img}
      placeholder={props.placeholder}
      options={props.options}
      isClearable={true}
      components={{
        IndicatorsContainer: IndicatorsContainer,
        ValueContainer: props.valueContainer || components.ValueContainer,
        Option: props.option,
        SingleValue: props.singleValue || components.SingleValue,
      }}
      className="react-select-container"
      classNamePrefix="react-select"
      styles={customStyles}
      isSearchable={false}
      closeMenuOnSelect={false}
    />
  );
}

function TableView({tableData,selectedRev}) {
    const selectedTable =
      selectedRev === "Panel Revenue"
        ? tableData.panel_revenue
        : tableData.manual_revenue;
    const accumulate_on_column = (index) =>
      selectedTable.reduce((acc, item) => acc + item[index], 0);
    let [
      total_manual_revenue,
      total_validation_revenue,
      total_pending_revenue,
      total_deducted_revenue,
    ] = [1, 2, 4, 5].map(accumulate_on_column);

  return (
    <div className="main_table">
      <div><button>Add Column</button></div>
      <table className="tab1" style={{ textAlign: "center" }}>
        <tr>
          <th>Month</th>
          <th>
            <CustomSelect
              placeholder={"Verified Revenue"}
              valueContainer={CustomValueContainer}
              options={options2}
              option={CustomOption}
              singleValue={CustomSingleValue}
            />{" "}
          </th>
          <th>
            <CustomSelect
              placeholder={"Validated Revenue"}
              valueContainer={CustomValueContainer}
              options={options2}
              option={CustomOption}
              singleValue={CustomSingleValue}
            />{" "}
          </th>
          <th>
            <CustomSelect
              placeholder={"Validation %"}
              valueContainer={CustomValueContainer}
              options={options2}
              option={CustomOption}
              singleValue={CustomSingleValue}
            />
          </th>
          <th>
            <CustomSelect
              placeholder={"Pending Revenue"}
              valueContainer={CustomValueContainer}
              options={options2}
              option={CustomOption}
              singleValue={CustomSingleValue}
            />
          </th>
          <th>
            <CustomSelect
              placeholder={"Deducted Revenue"}
              valueContainer={CustomValueContainer}
              options={options2}
              option={CustomOption}
              singleValue={CustomSingleValue}
            />
          </th>
        </tr>
        {selectedTable.map((item, index) => {
          return (
            <tr key={index} className={index % 2 !== 0 ? "oddcolour" : ""}>
              {item.map((item, index) => {
                if (index === 3)
                  return (
                    <td key={index} className="centre">
                      <PercentageDiv percentage={item.toFixed(2)} />
                    </td>
                  );
                if (index != 0) return <td key={index}>{item.toFixed(2)}</td>;
                if (index == 0)
                  return (
                    <td className="first_col" key={index}>
                      {item}
                    </td>
                  );
              })}
            </tr>
          );
        })}
        <tr className="last-row">
          <td>Total</td>
          <td>{total_manual_revenue.toFixed(2)}</td>
          <td>
            <Slider
              text={total_validation_revenue.toFixed(2)}
              percentage={(
                (total_validation_revenue / total_manual_revenue) *
                100
              ).toFixed(2)}
            />
          </td>
          <td></td>
          <td>
            <Slider
              text={total_pending_revenue.toFixed(2)}
              percentage={(
                (total_pending_revenue / total_manual_revenue) *
                100
              ).toFixed(2)}
            />
          </td>
          <td>
            <Slider
              text={total_deducted_revenue.toFixed(2)}
              percentage={(
                (total_deducted_revenue / total_manual_revenue) *
                100
              ).toFixed(2)}
            />
          </td>
        </tr>
      </table>
    </div>
  );
}
export default TableView;
