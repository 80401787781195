import axios from 'axios'
import React, { useEffect, useState } from 'react'
import * as Constants from '../../../data/constants';
import { getLocalStorageValue,getRequests } from '../../../util/util';
import FilterRow from '../../../components/filterV2/filtersRow';
import { DateRangeIcon } from '@mui/x-date-pickers';
// import { RoomService } from '@mui/icons-material';
import DashboardCards from '../../../components/dashboardCards/dashboardCards';
import { getAdvertisers } from '../../../apis/dropdown/dropdowns';
import TableModel from '../../../components/tableModel/tablemodel';
import { Pagination,CircularProgress,Tooltip  } from '@mui/material';

const LinkTestResults = () => {
    const [filterData, setFilterData] = useState({
        from_date:new Date(new Date().getTime()),
        end_date:new Date(new Date().getTime()),
    })
    const [responseData, setResponseData] = useState({})
    const [advertiserOptions, setAdvertiserOptions] = useState([])
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(25)
    const [totalResults, setTotalResults] = useState(0)

    const [loader, setLoader] = useState(false) 
    const [tableValues, setTableValues] = useState([])


    var defaultFilterData = {
        from_date:new Date(new Date().getTime()),
        end_date:new Date(new Date().getTime()),
    }

    if(!manualUseEffect){
        setManualUseEffect(true)
        getRequests(getAdvertisers(),setAdvertiserOptions)
    }


    const overrideSetFilterData = (value, filterItem) => {
        if (filterItem === 'dateRangePicker') {
            setFilterData(prev=> {return {...prev , ...value }})
        }
        else {
            setFilterData(prev=> {return {...prev , [filterItem.value]: value }});
        }
    }


    var filtersList = [
        {label:'Date Range',paramKey:'dateRange',value:'date_range',type:'daterangepicker',icon:DateRangeIcon},
        {label:'Offer Title',paramKey:'offerTitle',value:'offer_title',type:'textfield' },
        {label:'Advertiser',paramKey:'advertiser',value:'advertiser_id',type:'select',options:[...advertiserOptions]},
        {label:'Package Name',paramKey:'packageName',value:'package_name',type:'textfield'},
        // {label:'Link Status',paramKey:'linkStatus',value:'link_status',type:'select',options:[...tabOptions]},
        {label:'Submit Reset Button',type:'submit_reset_button',onSubmit:()=>{getSummaryData()},onReset:()=>{setFilterData(defaultFilterData)}},

    ]


    var statusDictColor = {
        'working':{
            color:'rgb(0, 150, 0)',
            backgroundColor:'rgb(217, 255, 200)',
            label:'Active'
        },
        'not-working':{
            color:'red',
            backgroundColor:'rgb(246,187,188)',
            label:'Disabled',
        }
        
    }
    const geoComponent = (itemValue, item) => {
        return itemValue[item.value].join(", ");
    }

    const timeComponent = (itemValue, item) => {
        const values = itemValue[item.value];
    
        if (values && values.length > 0) {
            const firstValue = values[0]; // Get the first element
            const remainingValues = values.join(', '); // Join the remaining elements
    
            // Return a span with a title for the tooltip
            return <Tooltip title={remainingValues}>
                            {firstValue}
                        </Tooltip>
        }
        return null; // If no value exists, return nothing or a fallback.
    };

    var tempTableHeaders = [
        {label:'ID',value:'id',show:true,width:'8%',minWidth:50,alignHead:'center',alignValue:'center',},
        {label:'Title',value:'title',show:true,width:'20%',minWidth:50,alignHead:'left',alignValue:'left'},//,customComponent:companyName},
        {label:'Advertiser',value:'advertiser_id',show:true,width:'6%',minWidth:30,alignHead:'center',alignValue:'center',},
        {label:'OS',value:'os',show:true,width:'8%',minWidth:40},
        {label:'External Offer Id',value:'external_offer_id',alignHead:'left',alignValue:'left',show:true,width:'10%',minWidth:40},
        {label:'Geo',value:'geo',show:true,width:'8%',minWidth:40,customComponent: geoComponent},
        {label:'Redirection',value:'last_redirection',show:true,width:'20%',minWidth:20,alignValue:'left',maxWidth:50},
        {label:'Link Working Time',value:'link_working_time',show:true,width:'10%',minWidth:50,alignValue:'center', customComponent: timeComponent},
        
    ]
    
    
    const getSummaryData = async(manual_limit=null,manual_page=null) => {
        var from_date = filterData.from_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        var end_date = filterData.end_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})

        const requestPayload = {
            start_date:from_date, 
            end_date:end_date,
            limit:manual_limit ? manual_limit : limit,
            page:manual_page ? manual_page : page
        };
        for (const filterItem of filtersList) {
            if (filterItem.type === 'daterangepicker') continue;
            const key = filterItem.value;

            if (filterData[key] !== '' && filterData[key] !== null && filterData[key] !== undefined) {
                if (filterItem.type === 'select')
                    requestPayload[key] = filterData[key].value
                else
                    requestPayload[key] = filterData[key]
            }
        }
        setLoader(true)
        await axios({
            method:'GET',
            url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'offerlinktest/results'),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params: requestPayload
        })
        .then(resp => {
            setLoader(false)
            setResponseData({
                '1':resp.data.result['1'] ? resp.data.result['1'] : 0,
                '0':resp.data.result['0'] ? resp.data.result['0'] : 0,
                'null':resp.data.result['null'] ? resp.data.result['null'] : 0,
                '2':resp.data.result['2'] ? resp.data.result['2'] : 0,
                'total':resp.data.result['total'] ? resp.data.result['total'] : 0,
            })
            setTableValues(resp.data.data)
            setTotalResults(resp.data.count)
        },

    )
    }
    useEffect(() => {
        getSummaryData()
        
        return () => {
            getSummaryData()
        }
    }, [])


    const getNotWorking = () => {
        if(responseData && responseData['0']){
            if(responseData['2']){
                return responseData['0']+responseData['2']
            }
            return responseData['0']
        }
        return 0
    }
    return (
        <div style={{padding:'20px',paddingTop:'0px'}}>
            <div style={{fontFamily:'Oswald',fontSize:'42px'}}>Linktest Service</div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between', marginBottom:'20px'}}>
               <DashboardCards styles={{borderRadius:'30px',background:"url('success_tile_bg.png')"}} value={responseData['total'] ? responseData['total'] : 0} label={'Total Links'}  cardWidth={'20%'} cardHeight={'80px'}/>
               <DashboardCards styles={{borderRadius:'30px',background:"url('success_tile_bg.png')"}} value={responseData['1'] ? responseData['1'] : 0} label={'Working Links'}  cardWidth={'20%'} cardHeight={'80px'}/>
               <DashboardCards styles={{borderRadius:'30px',background:"url('success_tile_bg.png')"}} value={getNotWorking()} label={'Not Working Links'}  cardWidth={'20%'} cardHeight={'80px'}/>
               <DashboardCards styles={{borderRadius:'30px',background:"url('success_tile_bg.png')"}} value={responseData['null'] ? responseData['null'] : 0} label={'Lost Links'}  cardWidth={'20%'} cardHeight={'80px'}/>
            </div>
            
            <FilterRow
                filtersList={filtersList}
                filterData={filterData}
                setFilterData={overrideSetFilterData}
                filterItemWidth={16}
            />
            {loader && 
                <div className="loaderWrapper">  
                    <div className="loader">  
                        <CircularProgress color="warning"/> 
                    </div>
                </div>
            }
            {
                !loader
                &&
                <TableModel 
                    tableHeaders={tempTableHeaders}
                    showLoader={loader} 
                    tableValues={tableValues}
                    setTableValues={setTableValues}
                    valueRowHeight={'40px'}
                />
            }
            {
                !loader 
                &&
                <div>
                    <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                    <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getSummaryData(e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                        {[25,50,75,100].map((item,index)=>{
                            return <option key={index} value={item}>{item}</option>
                        })}
                    </select>
                </div>
            }
            {
                !loader
                &&
                <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getSummaryData(limit,value);setPage(value);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
            }
            

        </div>
        



    )
}

export default LinkTestResults